import { Directive, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  NumberEight,
  NumberSix,
  NumberTen,
  NumberThree,
} from '../../shared/utils/constants/global.constants';

@Directive({
  selector: '[formControlName][appPhoneMask]',
})
export class PhoneMaskDirective implements OnInit, OnDestroy {
  valueSubscription: Subscription;
  constructor(public ngControl: NgControl) {}

  ngOnInit() {
    if (this.ngControl.name === 'mobile') {
      this.valueSubscription = this.ngControl.control.valueChanges.subscribe(
        (value) => {
          const newVal = this.onInputChangeMobile(value);
          this.ngControl.control.patchValue(newVal, { emitEvent: false });
        }
      );
    } else {
      this.valueSubscription = this.ngControl.control.valueChanges.subscribe(
        (value) => {
          const newVal = this.onInputChange(value);
          this.ngControl.control.patchValue(newVal, { emitEvent: false });
        }
      );
    }

  }

  onInputChange(event) {
    if (event === null) {
      return null;
    }
    let newVal = event.replace(/\D/g, '');
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= NumberThree) {
      newVal = newVal.replace(/^(\d{0,3})/, '$1');
    } else if (newVal.length <= NumberSix) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '$1-$2');
    } else if (newVal.length <= NumberTen) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
    } else {
      newVal = newVal.substring(0, NumberTen);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
    }
    if (newVal === '') {
      newVal = null;
    }
    return newVal;
  }


  onInputChangeMobile(event) {
    if (event === null) {
      return null;
    }
    let newVal = event.replace(/\D/g, '');
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 4) {
      newVal = newVal.replace(/^(\d{0,4})/, '$1');
    } else if (newVal.length <= NumberSix) {
      newVal = newVal.replace(/^(\d{0,4})(\d{0,2})/, '$1-$2');
    } else if (newVal.length <= NumberEight) {
      newVal = newVal.replace(/^(\d{0,4})(\d{0,2})(\d{0,2})/, '$1-$2-$3');
    } else {
      newVal = newVal.substring(0, NumberTen);
      newVal = newVal.replace(/^(\d{0,4})(\d{0,2})(\d{0,2})(\d{0,2})/, '$1-$2-$3-$4');
    }
    if (newVal === '') {
      newVal = null;
    }
    return newVal;
  }

  ngOnDestroy() {
    this.valueSubscription.unsubscribe();
  }
}
