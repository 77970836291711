import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomAddToCartService } from '../custom-add-to-cart.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CheckoutComponent } from '../../shared/modules/checkout/checkout.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-standard-cart-checkout',
  templateUrl: './standard-cart-checkout.component.html',
  styleUrls: ['./standard-cart-checkout.component.scss']
})
export class StandardCartCheckoutComponent implements OnInit,OnDestroy {
  @ViewChild('p2') public savePopover: NgbPopover;
  @ViewChild(CheckoutComponent) checkoutDetails;
cartDetails:any[];
stdCart=true;
showDisclaimer = false;
cartContainsWholesalerProducts = false;
usergetDataObj: any;
getuserData: any;
searchedClientList:any;
selectedData:any;
listSelectedData:any;
userAccountgetData: any;
saveCartName:string;
errorMsg:string;
cartId:string;
productEntries: any = [];
saveCartSub:Subscription;
cartItemsSub:Subscription;
userAccountSub:Subscription;
invalidNameSub:Subscription;
  constructor(private readonly addToCart:CustomAddToCartService,
    readonly user: UserAccountFacade,private readonly cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.user.get().subscribe((res) => {
      if (res) {
        this.userAccountgetData = res;
        this.getUserAccountData(this.userAccountgetData.userName);
      }
    });
    this.cdr.detectChanges();
    this.cartItemsSub=this.addToCart.cartItems$.subscribe((cartData: any) => {
      if (cartData) {
        let stdCartDetails = [];
        this.productEntries=[];
        stdCartDetails = cartData?.childCarts;
        this.cartId=cartData?.code;
        if (stdCartDetails) {
          stdCartDetails = stdCartDetails.filter(
            (item) => item.cartType === 'STANDARD'
          );
          for (const cartDetails of stdCartDetails) {
            for (const entries of cartDetails.entries) {
              if (!this.cartContainsWholesalerProducts && !!entries?.wholesalerProduct) {
                this.cartContainsWholesalerProducts = true;
                this.showDisclaimer = true;
              }
              this.productEntries.push(entries);
            }
          }
          this.cdr.detectChanges();
        }
      }
    });
  }
  toggle(popover) {
    if (popover.isOpen()) {
      popover.close();
    } else {
      popover.open();
    }
    this.errorMsg='';
  }
  getUserAccountData(userId): void {
      this.userAccountSub=this.addToCart.userAccountDetails.subscribe((userDataget) => {
        if(userDataget){
        this.getuserData = userDataget.res.shippingAddresses;
        this.searchedClientList = userDataget.res.shippingAddresses;
        this.selectedData = this.getuserData[0];
        }
      });
      this.cdr.detectChanges();
  }
  saveOrder(): void {
    const reg = /^[^`!@#~$%\^&*()_+\-={};':"\\|[\]\\.,<>/\/?~]*$/;
    if (this.saveCartName && RegExp(reg).test(this.saveCartName)) {
    this.addToCart.saveCartDetails(this.saveCartName,this.cartId);
    this.invalidNameSub=this.addToCart.invalidCartName$.subscribe((res)=>{
      if(res){
      for(const error of res?.error?.errors){
        this.errorMsg=error?.message;
        }
      }
    });
    this.saveCartSub=this.addToCart.saveCartSuccess$.subscribe((res)=>{
      if(res){
        if(this.savePopover){
          this.savePopover.close();
        }
        this.errorMsg='';
      }
    });
  }else{
    if(this.saveCartName){
      this.errorMsg= 'Save cart name is not valid.';
    }else{
    this.errorMsg = 'Cart name cannot be blank';
    }
  }
  }

  exportXls() {
    const cartProducts = [];
    if (!this.cartContainsWholesalerProducts) {
      for (const entries of this.productEntries) {
        cartProducts.push({
          'Customer': this.selectedData?.sapCustomerID,
          'Material ID': entries?.product?.code,
          'Product Name': entries?.product?.name,
          'Quantity': entries?.quantity,
          'PO Number': this.checkoutDetails?.orderNo
        });
      }
    } else {
      for (const entries of this.productEntries) {
        cartProducts.push({
          'Customer': this.selectedData?.sapCustomerID,
          'Material ID': entries?.product?.code,
          'Product Name': entries?.product?.name,
          'Quantity': entries?.quantity,
          'PO Number': this.checkoutDetails?.orderNo,
          'WHS': entries?.wholesalerDisplayName
        });
      }
    }
    this.addToCart.downloadXls(cartProducts, 'Checkout');
  }
  omit_special_char(event){
    this.errorMsg='';
    const reg = /^[^`@~$%\^&+={}|[\]\\<>/]*$/;
    return new RegExp(reg).test(event.key);
  }

  closeDisclaimerAlert() {
    this.showDisclaimer = false;
  }

  ngOnDestroy() {
    this.cartItemsSub?.unsubscribe();
    this.saveCartSub?.unsubscribe();
    this.invalidNameSub?.unsubscribe();
    this.userAccountSub?.unsubscribe();
  }
}
