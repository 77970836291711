import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CsAgentAuthService } from '@spartacus/asm/root';
import {
  ConverterService,
  OccEndpointsService,
  OccProductAdapter,
  OccRequestsOptimizerService,
} from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Injectable({
  providedIn: 'root',
})
export class CustomOccProductAdapter extends OccProductAdapter {
  constructor(
    protected http: HttpClient,
    protected converterService: ConverterService,
    protected occEndpointsService: OccEndpointsService,
    protected userService: UserAccountFacade,
    protected requestOptimizer: OccRequestsOptimizerService,
    protected csAgentAuthService: CsAgentAuthService
  ) {
    super(http, occEndpointsService, converterService, requestOptimizer);
  }
  protected getEndpoint(code: string, scope?: string): string {
    let userId;
    let users = 'users';
    const params = {
      fields: 'DEFAULT',
      purchasable: '',
      baseOptions: '(DEFAULT)',
      variantOptions: '(DEFAULT)',
      variantType: '',
    };
    this.csAgentAuthService
      .isCustomerEmulated()
      .subscribe((agent) => {
        if (agent) {
          this.userService
            .get()
            .subscribe((user) => {
              if (user) {
                userId = user.uid;
              } else {
                userId = 'anonymous';
              }
            })
            .unsubscribe();
        } else {
          users = '';
          userId = '';
        }
      })
      .unsubscribe();
    return this.occEndpoints.buildUrl('products', {
      urlParams: { users: users, userId: userId, productCode: code },
      queryParams: { ...params },
    });
  }
}
