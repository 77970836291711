import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserAccountFacade } from '@spartacus/user/account/root';
import * as moment from 'moment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Subscription } from 'rxjs';
import {
  Sixty,
  Thousand,
  TwentyFour,
} from '../../shared/utils/constants/global.constants';
import { OpenStatementsService } from '../open-statements.service';
import { StatementDisplayComponent } from '../statement-display/statement-display.component';

const statementCssClass = 'col-md-10 col-sm-9';
const accountOverviewCssClass = 'col-md-12 col-sm-12';
const dateFormat = 'yyyy-MM-dd';

@Component({
  selector: 'app-open-statements',
  templateUrl: './open-statements.component.html',
  styleUrls: ['./open-statements.component.scss'],
})
export class OpenStatementsComponent implements OnInit, OnDestroy {
  @Input() hideAside: boolean;
  showSection = false;
  statementWidth: string = statementCssClass;
  statementsData: any;
  showPortal = false;
  details: any;
  showModal = false;
  maxSelectionDate = new Date();
  fromDate: any;
  userId;
  toDate: any;
  accountId = null;
  userAcccountSub: Subscription;
  statementSub: Subscription;
  reloadSub: Subscription;

  downloadData: void;
  excelSubscription: Subscription;

  pdf: Subscription;
  facetsList: any = [];
  singleDateError = false;
  dateError = false;
  userInfo: any;
  filterDateFormat = 'DD/MM/YYYY';

  constructor(
    private readonly stService: OpenStatementsService,
    private readonly cdr: ChangeDetectorRef,
    private readonly datePipe: DatePipe,
    private readonly localeService: BsLocaleService,
    private readonly modalService: NgbModal,
    private readonly user: UserAccountFacade,
    private readonly cd: ChangeDetectorRef
  ) {}
  public windowReference = null;

  ngOnInit(): void {
    if (!!this.hideAside) {
      this.statementWidth = accountOverviewCssClass;
      this.showSection = this.hideAside;
    } else {
      this.statementWidth = statementCssClass;
      this.showSection = false;
    }
    this.stService.getAccountId();
    this.userAcccountSub = this.stService.userAccountIdDetails.subscribe(
      (res) => {
        this.accountId = res.defaultB2BUnit.uid;
      }
    );

    this.statementSub = this.stService
      .getStatements({
        accountId: this.accountId,
        fields: 'DEFAULT',
      })
      .subscribe((res) => {
        if (res) {
          this.statementsData = JSON.parse(JSON.stringify(res));
          this.cdr.detectChanges();
        }
      });

    this.reloadSub = this.stService.reloadComponent.subscribe((res) => {
      this.showPortal = !res;
      this.cdr.detectChanges();
    });
    this.cdr.detectChanges();
  }

  openNewModalPopup(endDate, startDate) {
    this.details = { fromDte: startDate, toDte: endDate };
    const modalRef = this.modalService.open(StatementDisplayComponent, {
      size: 'lg',
      scrollable: false,
    });
    modalRef.componentInstance.toShow = true;
    modalRef.componentInstance.statement = this.details;
  }

  generateSavePdf(endDate, startDate) {
    this.showPortal = true;
    this.details = { fromDte: startDate, toDte: endDate };
    this.downloadData = this.stService.loadStatements.next(this.details);
    this.stService.loadStatements.next(this.details);
  }

  viewCurrent() {
    const currentDate = new Date();
    const myDate = this.datePipe.transform(currentDate, dateFormat);
    const fromDateTimeStamp = new Date(
      new Date(this.statementsData?.dateMap[0].value).getTime() +
        TwentyFour * Sixty * Sixty * Thousand
    );
    const fromDate = this.datePipe.transform(fromDateTimeStamp, dateFormat);
    this.openNewModalPopup(myDate, fromDate);
  }

  viewPDF() {
    if (!!this.statementsData) {
      const currentDate = new Date();
      const myDate = this.datePipe.transform(currentDate, dateFormat);
      const fromDateTimeStamp = new Date(
        new Date(this.statementsData?.dateMap[0].value).getTime() +
          TwentyFour * Sixty * Sixty * Thousand
      );
      const fromDate = this.datePipe.transform(fromDateTimeStamp, dateFormat);
      this.generateSavePdf(myDate, fromDate);
    }
  }

  downloadExcel() {
    const currentDate = new Date();
    const myDate = this.datePipe.transform(currentDate, dateFormat);
    const params = {
      endDate: myDate,
      fields: 'DEFAULT',
      accountId: this.accountId,
    };
    const statementslist = [[], []];
    this.stService.getExcelData(params);
    this.excelSubscription = this.stService.excelData$.subscribe((res) => {
      if (res !== null) {
        for (const entries of res?.statementTableData) {
          statementslist[0].push({
            'DOCUMENT DATE': entries?.documentDate,
            'DUE DATE': entries?.dueDate,
            'TYPE OF TRANS': entries?.transType,
            'DOCUMENT NUMBER': entries?.documentNumber,
            AMOUNT: entries?.amountDue,
            CASH: entries?.currency,
            'PAYMENT CONDITIONS': entries?.termsOfPayment,
          });
        }
        this.cdr.detectChanges();
        this.stService.downloadXls(statementslist, 'Statements');
      }
    });
  }

  ngOnDestroy() {
    this.userAcccountSub?.unsubscribe();
    this.statementSub?.unsubscribe();
    this.reloadSub?.unsubscribe();
  }

  clearFilters() {
    this.facetsList = [];
    this.fromDate = undefined;
    this.toDate = undefined;

    this.stService.getAccountId();
    this.userAcccountSub = this.stService.userAccountIdDetails.subscribe(
      (res) => {
        this.accountId = res.defaultB2BUnit.uid;
      }
    );

    this.statementSub = this.stService
      .getStatements({
        accountId: this.accountId,
        fields: 'DEFAULT',
      })
      .subscribe((res) => {
        if (res) {
          this.statementsData = JSON.parse(JSON.stringify(res));
          this.cdr.detectChanges();
        }
      });
  }

  applyFilter(type?: string) {
    if (this.fromDate && this.toDate) {
      if (this.fromDate.getTime() > this.toDate.getTime()) {
        this.dateError = true;
        return false;
      } else {
        this.dateError = false;
      }
    }
    this.userInfo = this.user.get().subscribe((userData) => {
      this.userId = userData;
    });
    const filters = this.getfilterQuery();
    this.showFacets(JSON.stringify(filters));
    this.stService.getAccountId();
    this.userAcccountSub = this.stService.userAccountIdDetails.subscribe(
      (res) => {
        this.accountId = res.defaultB2BUnit.uid;
      }
    );
    filters.accountId = this.accountId;
    this.statementSub = this.stService
      .getStatements(filters)
      .subscribe((res) => {
        if (res) {
          this.statementsData = JSON.parse(JSON.stringify(res));
          this.cdr.detectChanges();
        }
      });

    this.cd.detectChanges();
    return true;
  }

  showFacets(data) {
    const facets = JSON.parse(data);
    delete facets.fields;
    if (facets.fromDate) {
      facets.date = `${moment(this.fromDate).format(
        this.filterDateFormat
      )} - ${moment(this.toDate).format(this.filterDateFormat)}`;
      delete facets.fromDate;
      delete facets.toDate;
    }
    this.facetsList = Object.entries(facets).map(([type, value]) => ({
      type,
      value,
    }));
  }

  getfilterQuery() {
    const data: any = {
      fields: 'DEFAULT',
    };
    if (this.fromDate && this.toDate) {
      data.fromDate = moment(this.fromDate).format('DD-MM-YYYY');
      data.toDate = moment(this.toDate).format('DD-MM-YYYY');
    } else if (
      (!this.fromDate && this.toDate) ||
      (this.fromDate && !this.toDate)
    ) {
      this.singleDateError = true;
    }
    return data;
  }

  removeFacet(filter) {
    this.facetsList.forEach((element, index) => {
      if (element === filter) {
        this.facetsList.splice(index, 1);
        if (filter.type === 'date') {
          this.fromDate = undefined;
          this.toDate = undefined;
        }
        this.cd.detectChanges();
        this.applyFilter();
      }
    });
  }

  validateDate() {
    if (this.fromDate && this.toDate) {
      this.singleDateError = false;
      if (this.fromDate.getTime() > this.toDate.getTime()) {
        this.dateError = true;
      } else {
        this.dateError = false;
      }
    }
  }

  /*generateExcel() {
    const filename = "statements";
    let data = [];
    data = this.computeDataForExcel();
    this.acService.downloadXls(data, filename, true);
  }
 
 
  computeDataForExcel() {
    const data = [];
      const statementsHeaders = [];
      for (const item of this.excelInvoiceHeaderIds) {
        statementsHeaders.push(document.getElementById(item).textContent);
      }
      this.paymentList.forEach((element) => {
        let invDocNo = '';
        invDocNo = this.findAccurateDocNo(element);
        data.push({
          [statementsHeaders[0]]: element.docDate,
          [statementsHeaders[1]]: invDocNo,
          [statementsHeaders[NumberTwo]]: element.docDate,
          [statementsHeaders[NumberThree]]: element.amount,
          //[statementsHeaders[NumberFour]]: element.amount,
        });
      });
   
    return data;
  }*/
}
