let sortDir = 1;
const caretUpClass = 'fa-caret-up';
const caretDownClass = 'fa-caret-down';
export class CommonUtils {
  static onSortClick(event, fieldName, dataList, type: string): any {
    const target = event.currentTarget.children;
    const classList = target[0].classList;

    if (classList.contains(caretUpClass)) {
      classList.remove(caretUpClass);
      classList.add(caretDownClass);
      sortDir = -1;
    } else {
      classList.add(caretUpClass);
      classList.remove(caretDownClass);
      sortDir = 1;
    }
    return CommonUtils.sortArr(fieldName, dataList, type, sortDir);
  }

  static sortArr(colName: string, dataList: any[], type: string, sortNum): any {
    dataList.sort((a, b) => {
      if (type === 'Date') {
        a = a[colName].split('/').reverse().join('');
        b = b[colName].split('/').reverse().join('');
      } else if(type === 'Number') {
        a = !!a[colName] ? parseInt(a[colName]) : 0;
        b = !!b[colName] ? parseInt(b[colName]) : 0;
      } else {
        a = a[colName].toLowerCase();
        b = b[colName].toLowerCase();
      }
      return type === 'Number' ? (a - b) * sortNum : a.localeCompare(b) * sortNum;
    });
    return dataList;
  }

  static dateComparison(dueDate, currentDate): boolean {
    const curr_date = currentDate.split('/').reverse().join('');
    const due_date = dueDate.split('/').reverse().join('');
    return parseInt(due_date) < parseInt(curr_date);
  }
}
