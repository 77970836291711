import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { UserAccountFacade } from '@spartacus/user/account/root';
import * as html2pdf from 'html2pdf.js';
import { ExcelDownloadService } from '../../../app/shared/services/excel-download.service';
import { SharedService } from '../../shared/services/shared.service';
import { MakeAPaymentService } from '../make-a-payment.service';

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.scss'],
})
export class PaymentConfirmationComponent implements OnInit {
  creditPaymentList: any = [];
  invoicePaymentList: any = [];
  creditConfirmationList: any = [];
  creditInvoice: string;
  currentDate = new Date();
  userData: any;
  personName: string;
  compNameAdBanner1 = 'InvoiceBannerComponent';
  invoiceConfirmList: any = [];
  selectedCardDetails: any;
  dispCardNumber: any;
  disabled: boolean;
  isPDF: boolean;
  errorMsg: string;
  authErrorMsg: string;
  errorMsg1: string;
  asmEmailDetails: any;
  isAsmEnabled: any;
  userSubscription: any;
  csAgentAuthSubscription: any;

  constructor(
    private readonly datePipe: DatePipe,
    private readonly sharedService: SharedService,
    private readonly router: Router, private readonly user: UserAccountFacade,
    public excelService: ExcelDownloadService, private readonly cdr: ChangeDetectorRef,
    private readonly makeAPayment: MakeAPaymentService,
    private readonly csAgentAuthService: CsAgentAuthService
  ) { }

  ngOnInit(): void {
    const successMsg = 'We have processed your request.';
    this.userSubscription = this.user.get().subscribe((res) => {
      if (res) {
        this.userData = res;
      }
    });
    this.personName = this.sharedService.getSessionStorage('creditPersonName');
    const confirmationList = this.sharedService.getSessionStorage('creditConfirmation');
    const errMsg = 'Please try again later or call us at 0770618663/07705098290509829.';
    const List = this.sharedService.getSessionStorage('creditData');
    if (List !== null) {
      this.creditPaymentList = List;
      this.creditConfirmationList = confirmationList;
      this.creditInvoice = '';
      if (this.creditConfirmationList !== null) {
        const data = this.creditConfirmationList.invoiceCreditPayResponseList.filter((item) =>
          item.type === 'E');
        if (data.length > 0) {
          this.errorMsg = this.creditMessage();
          this.errorMsg1 = errMsg;
        } else {
          this.errorMsg = successMsg;
          this.errorMsg1 = '';
        }
      }
    } else {
      const FOUR = 4;
      this.creditInvoice = 'invoiceTable';
      this.selectedCardDetails = this.sharedService.getSessionStorage('selectedCard');
      this.dispCardNumber = this.selectedCardDetails?.displayCardNumber.split('-');
      if (this.dispCardNumber !== undefined && this.dispCardNumber !== null) {
        this.dispCardNumber.cardNumber = this.dispCardNumber[FOUR];
      }
      this.invoicePaymentList = this.sharedService.getSessionStorage('invoicesData');
      this.invoiceConfirmList = this.sharedService.getSessionStorage('paymentConfirmation');
      if (this.invoiceConfirmList !== null) {
        this.invoiceConfirmList?.openinvoiceItems.forEach((element) => {
          element.netBalance = element.netBalance.toString();
          element.payAmount = element.payAmount.toString();
        });
        if (this.invoiceConfirmList?.authMessage === undefined) {
          if (this.invoiceConfirmList?.message === 'Approved') {
            this.errorMsg = successMsg;
            this.errorMsg1 = '';
          } else {
            this.errorMsg = this.invoiceMessage();
            this.errorMsg1 = errMsg;
          }
        } else {
          this.authErrorMsg = 'Credit authorization failed';
          this.errorMsg = this.invoiceMessage();
          this.errorMsg1 = errMsg;
        }
      }
    }
    this.csAgentAuthSubscription = this.csAgentAuthService.isCustomerEmulated().subscribe((res) => {
      this.isAsmEnabled = res ? res : false;
      if (this.isAsmEnabled) {
        this.asmEmailDetails = this.sharedService.getLocalStorage('asmEmail');
        this.personName = this.sharedService.getLocalStorage('creditPersonName');
        const confirmationListAsm = this.sharedService.getLocalStorage('creditConfirmation');
        const ListAsm = this.sharedService.getLocalStorage('creditData');
        if (ListAsm !== null) {
          this.creditPaymentList = ListAsm;
          this.creditConfirmationList = confirmationListAsm;
          this.creditInvoice = '';
          const data = this.creditConfirmationList.invoiceCreditPayResponseList.filter((item) =>
            item.type === 'E');
          if (data.length > 0) {
            this.errorMsg = this.creditMessage();
            this.errorMsg1 = errMsg;
          } else {
            this.errorMsg = successMsg;
            this.errorMsg1 = '';
          }
        } else {
          const FOUR = 4;
          this.creditInvoice = 'invoiceTable';
          this.selectedCardDetails = this.sharedService.getLocalStorage('selectedCard');
          this.dispCardNumber = this.selectedCardDetails?.displayCardNumber.split('-');
          if (this.dispCardNumber !== undefined && this.dispCardNumber !== null) {
            this.dispCardNumber.cardNumber = this.dispCardNumber[FOUR];
          }
          this.invoicePaymentList = this.sharedService.getLocalStorage('invoicesData');
          this.invoiceConfirmList = this.sharedService.getLocalStorage('paymentConfirmation');
          this.invoiceConfirmList?.openinvoiceItems.forEach((element) => {
            element.netBalance = element.netBalance.toString();
            element.payAmount = element.payAmount.toString();
          });
          if (this.invoiceConfirmList?.authMessage === undefined) {
            if (this.invoiceConfirmList?.message === 'Approved') {
              this.errorMsg = successMsg;
              this.errorMsg1 = '';
            } else {
              this.errorMsg = this.invoiceMessage();
              this.errorMsg1 = errMsg;
            }
          } else {
            this.authErrorMsg = 'Credit authorization failed';
            this.errorMsg = this.invoiceMessage();
            this.errorMsg1 = errMsg;
          }
        }
      }
    });
  }
  creditMessage() {
    let msg = 'Credit ';
    const totalSelectedData = [];
    this.creditConfirmationList.invoiceCreditPayResponseList.forEach((element) => {
      let selectedData;
      if (element.type === 'E') {
        if (element.credDocType === 'DZ') {
          selectedData = element.credDohdrTxt;
        } else {
          selectedData = element.credRefDocnum;
        }
        totalSelectedData.push(selectedData);
      }
    });
    totalSelectedData.forEach((element, index) => {
      msg += (`#${element}`);
      if ((totalSelectedData.length - 1) !== index) {
        msg += ', ';
      }
    });
    msg += ' cannot be processed at this time.';
    return msg;
  }
  invoiceMessage() {
    let msg = 'Invoice ';
    const totalSelectedData = [];
    this.invoiceConfirmList.openinvoiceItems.forEach((element) => {
      if (element.message !== 'Approved') {
        totalSelectedData.push(element.invoiceNumber);
      }
    });
    totalSelectedData.forEach((element, index) => {
      msg += (`#${element}`);
      if ((totalSelectedData.length - 1) !== index) {
        msg += ', ';
      }
    });
    msg += ' cannot be processed at this time.';
    return msg;
  }
  excelDownload() {
    const excelArray = [];
    let excelName;
    if (this.creditInvoice === 'invoiceTable') {
      excelName = 'InvoiceConfirmation';
      const FOUR = 4;
      this.invoiceConfirmList?.openinvoiceItems.forEach((element) => {
        const obj = {
          'Status': this.invoiceConfirmList.message,
          'Invoice #': element.invoiceNumber,
          'Due date': element.dueDate,
          'Net invoice balance': element.netBalance,
          'Payment amount': element.payAmount,
          'Payment method': `${element.cardType},${this.dispCardNumber[FOUR]}`,
          'Payment date': this.datePipe.transform(this.currentDate, 'dd/MM/yyyy')
        };
        excelArray.push(obj);
      });
    } else {
      excelName = 'CreditConfirmation';
      this.creditConfirmationList?.invoiceCreditPayResponseList.forEach((element) => {
        let credit;
        if (element.credDocType !== 'DZ') {
          credit = element.credRefDocnum;
        } else {
          credit = element.credDohdrTxt;
        }
        let invoice;
        if (element.invDocType !== 'DZ') {
          invoice = element.invRefDocnum;
        } else {
          invoice = element.invDohdrTxt;
        }
        const obj = {
          'Status': element.msg,
          'Credit #': credit,
          'Apply to invoice#': invoice,
          'Credit applied': element.invAmt,
          'New credit balance': element.credAmt,
          'New invoice balance': element.invBal,
          'Credit applied date': this.datePipe.transform(this.currentDate, 'dd/MM/yyyy')
        };
        excelArray.push(obj);
      });
    }
    this.excelService.exportAsExcelFile(excelArray, excelName, true);
  }
  return(): void {
    this.router.navigate(['/my-account/invoices']);
  }
  generatePDF() {
    this.isPDF = true;
    this.cdr.detectChanges();
    const options = {
      margin: 10,
      filename: this.creditInvoice === 'invoiceTable' ? 'PaymentConfirmation.pdf' : 'CreditConfirmation.pdf',
      image: { type: 'jpeg' },
      html2canvas: {},
      jsPDF: { orientation: 'portrait' },
    };
    const elemId = this.creditInvoice === 'invoiceTable' ? 'invoice-table' : 'credits-table';
    const element = document.getElementById(elemId);
    const domClone = element.cloneNode(true);
    let section = null;
    section = document.createElement('SECTION');
    section.setAttribute('id', 'pdf-invoices');
    section.appendChild(document.getElementById('line-sanofi'));
    section.appendChild(domClone);
    document.getElementById('pdf-dummy').appendChild(section);
    const updatedElem = document.getElementById('pdf-dummy');
    html2pdf().from(updatedElem).set(options).save();
    this.isPDF = false;
    this.cdr.detectChanges();
  }

  invoicePdf(invoiceNo): void {
    this.makeAPayment.downloadPdf(invoiceNo);
  }

  creditPdf(creditNo): void {
    this.makeAPayment.downloadPdf(creditNo);
  }
  print() {
    window.print();
  }
  ngOnDestroy() {
    this.userSubscription?.unsubscribe();
    this.csAgentAuthSubscription?.unsubscribe();
  }
}
