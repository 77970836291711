import { Component, Input, OnInit } from '@angular/core';
import { CmsService, RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { CustomAddToCartService } from '../../custom-add-to-cart.service';

@Component({
  selector: 'app-empty-cart',
  templateUrl: './empty-cart.component.html',
  styleUrls: ['./empty-cart.component.scss']
})
export class EmptyCartComponent implements OnInit {
  @Input() compName;
  cmsResponse: any;
  emptycontent:any;
  userAccountgetData: any;
  sessionStorage = sessionStorage;

  constructor(
    private readonly route: RoutingService,
    private readonly cmsData: CmsService,
    private readonly addToCart: CustomAddToCartService,
    private readonly user: UserAccountFacade,
    ) { }

  ngOnInit(): void {
    this.user.get().subscribe((res) => {
      if (res) {
        this.userAccountgetData = res;
      }
    });
    this.addToCart.getAllCarts(this.userAccountgetData?.userName);
    this.cmsData.getComponentData(this.compName).subscribe((res) => {
      this.cmsResponse = res;
    }
    );
    if (this.cmsResponse && this.cmsResponse.content) {
      this.emptycontent = this.cmsResponse.content;
    }
  }
  navigateToPdp() {
    this.route.goByUrl('/Open-Catalogue/c/1');
  }

  navigateToSavedOrder(){
    this.route.goByUrl('/cart/save');
  }
  navigateToFlu(){
    this.route.goByUrl('/influenza/dashboard');
  }
}
