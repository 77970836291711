<cx-searchbox [quickOrder]='true' [searchValueClear]="clearSearchValue"
    (selectedProductEvent)="onProductSelected($event)" [config]='QUICKORDER_SEARCH_BOX_CONFIG'></cx-searchbox>

<section *ngIf="quickOrderDetail" class="quick-prod-details">
    <section *ngIf="!productInfo.flu" class="row unit-calc">

        <section class="price-details">
            <b>{{'plp.yourPrice'| cxTranslate }}</b>
            <section class="price-total">
                <span *ngIf="productInfo?.price == undefined || productInfo?.price?.value === undefined">-</span>
                <span *ngIf="productInfo?.price && productInfo?.price?.value !== undefined">
                    <span>{{productInfo?.price?.value | localCurrency}}</span>
                </span>
            </section>
        </section>

        <section class="no-price" *ngIf=" productInfo?.price == null || productInfo?.price == undefined || productInfo?.price?.value == 0.0 "><p>{{'plp.noPrice' | cxTranslate}}</p></section>

        <section class="units" *ngIf="(productInfo?.price != null && productInfo?.price != undefined && productInfo?.price?.value != undefined && productInfo?.price?.value != 0.0) && (!productInfo?.discontinued && productInfo?.available)">

            <b class="prodUnits">{{'plp.units' | cxTranslate}}
            </b>
            <section class="unit-area">
                <span class="disabled " aria-hidden="true" id="{{'minusIcon'+productInfo.code+'q'}}"
                    (click)="performQuantityComputation(2,productInfo.code+'q',productInfo.quantityPerUnit)">
                    <img src="../../assets/icons/less.svg" id="{{'unitMinus'+productInfo.code+'q'}}"
                        class="disabled-img" alt="">
                    <img src="../../assets/icons/less-disable.svg" id="{{'unitMinusDisable'+productInfo.code+'q'}}"
                        alt="">
                </span>
                <input type="number" class="prod-quantity text-center" value='1'
                    (keyup)="performQuantityComputation(null,productInfo.code+'q',productInfo.quantityPerUnit)"
                    onkeypress="return /[0-9a-zA-Z]/i.test(event.key)" id="{{'prodQuantity'+productInfo.code+'q'}}"
                    maxlength="4" />
                <span class="" aria-hidden="true" id="{{'plusIcon'+productInfo.code+'q'}}"
                    (click)="performQuantityComputation(1,productInfo.code+'q',productInfo.quantityPerUnit)">
                    <img src="../../assets/icons/more.svg" id="{{'unitPlus'+productInfo.code+'q'}}" alt="">
                    <img src="../../assets/icons/more-disable.svg" id="{{'unitPlusDisable'+productInfo.code+'q'}}"
                        class="disabled-img" alt="">
                </span>
            </section>
            <section class="dosessection" id="{{'noOfDoses'+productInfo.code+'q'}}">
                {{productInfo.quantityPerUnit}} {{'plp.doses' | cxTranslate}}</section>
        </section>

        <button type="button" class="btn blue-button quickorder-button"
            *ngIf="(productInfo?.price != null && productInfo?.price != undefined && productInfo?.price?.value !== undefined && productInfo?.price?.value !== 0.0) && (!productInfo?.discontinued && productInfo?.available)" [ngbPopover]=popTitleSuccess placement="bottom"
            triggers="manual" #p2="ngbPopover" popoverClass="savesuccess-msg" [closeDelay]="500"
            (click)="addToCart(productInfo?.code+'q',productInfo?.code)">

            <ng-template #popTitleSuccess>
                <div class="cart-value-added">
                    <img src="../../../assets/icons/confirmation-positive-green.png" class="positive-green-icon"
                        alt="suc">
                    <span class="confirmation-itemsadded">{{itemAddedQuantity}}
                        {{'addToCartItems.addEntrySuccess'|cxTranslate}}</span>
                </div>
            </ng-template>
            <i *ngIf="buttonName === 'reserveNow'" class="fa fa-calendar" aria-hidden="true"></i>
            {{ 'addToCartItems.' + buttonName | cxTranslate}}
        </button>
        <section *ngIf="(productInfo?.price != null && productInfo?.price != undefined && productInfo?.price?.value != undefined && productInfo?.price?.value != 0.0) && (!productInfo?.discontinued && productInfo?.available)">
            <section *ngIf="productInfo.sapAllocation">
                <b class="prod-allocation">{{'plp.productAllocation' | cxTranslate}}</b>
                <p class="allocation-p">{{'plp.allocationDetails' | cxTranslate}}
            </section>
        </section>
    </section>


    <section *ngIf="productInfo?.discontinued || !productInfo?.available">
        <section *ngIf="productInfo?.discontinued && productInfo?.available">
            <p class="product-discontinued-txt1">
                {{'plp.discontinued' | cxTranslate}}</p>
        </section>
        <section *ngIf="productInfo?.discontinued && !productInfo?.available">
            <p class="product-discontinued-txt1">
                {{'plp.discontinued' | cxTranslate}}</p>
        </section>
        <section *ngIf="!productInfo.discontinued && !productInfo?.available" class="m0 left-align">
            <b class="prod-allocation class-avail"
                *ngIf="productInfo?.availabilityText">{{productInfo?.availabilityText}}</b>
            <b class="prod-allocation class-avail" *ngIf="!productInfo?.availabilityText">{{'plp.noavailabilityText' |
                cxTranslate}}</b>
        </section>
    </section>

    <section *ngIf="productInfo?.flu" class="flu-section">
        <section>
            <button type="button" class="btn blue-button m0-spacing"
                *ngIf="productInfo.flu && productInfo.available && !productInfo.discontinued"
                (click)="navToReservenow()">
                <i *ngIf="buttonName === 'reserveNow'" class="fa fa-calendar" aria-hidden="true"></i>
                {{'plp.reserve'|cxTranslate}}</button>
        </section>
    </section>
</section>
