const invoiceConstant = 'S';
const creditConstant = 'H';
const creditCardNumberIndex = 2;
const precision = 2;
export class InvoiceUtils {
  static getInvoiceTableData(data): any[] {
    const invoiceData: any[] = [];
    data.forEach((item) => {
      if (item.dbCrInd === invoiceConstant) {
        item.paymentAmount = item.amount;
        item.checked = false;
        item.invalidAmount = false;
        invoiceData.push(item);
      }
    });
    return invoiceData;
  }

  static getCreditTableData(data, dropDownSource): any[] {
    const creditData: any[] = [];
    data.forEach((item) => {
      if (item.dbCrInd === creditConstant) {
        item.creditsApplied = parseFloat(item.amount).toFixed(precision);
        item.creditDropDown = dropDownSource;
        item.selectedInvoice = null;
        item.checked = false;
        item.errorMsg = false;
        item.checkedAndNotFilledInvoice = false;
        creditData.push(item);
      }
    });
    return creditData;
  }
  static getCreditDropDownSource(data): any[] {
    const creditDataSource: any[] = [];
    data.forEach((item) => {
      let displayNumber;
      if (item.docType === 'DZ') {
        displayNumber = item.dochdrtxt;
      } else if (item.docType === 'YR') {
        displayNumber = item.refhdr;
      } else {
        displayNumber = item.refDocNo;
      }
      creditDataSource.push({
        docNo: item.docNo,
        amount: item.amount,
        displayValue: displayNumber + ' ($' + item.amount + ')',
        invoice_amount: item.amount,
        invoice_fisc_year: item.fiscYear,
        invoice_item_no: item.itemNum,
        invoice_due_date: item.dueDate,
        includeInList: true,
        pendingBalance: null,
        refDocNo: item.refDocNo,
        invoice_docType: item.docType,
        invoice_dochdrtxt: item.dochdrtxt,
        invoice_refhdr: item.refhdr,
      });
    });
    return creditDataSource;
  }
  static formatCardData(data): any {
    data.forEach((res) => {
      const value = res.cardNumber.split('-');
      res.displayCardNumber = `xxxx-xxxx-xxxx-xxxx-${value[creditCardNumberIndex]}`;
    });
    return data;
  }

  static fetchdropDownSource(
    creditList,
    creditDropDownSource,
    listofSelectedInvoices,
    pendingBalanceToBeAdded?,
    invoiceIdToBeAltered?
  ): any {
    creditList.forEach((res) => {
      if (!res.selectedInvoice) {
        res.creditDropDown = [];
        creditDropDownSource.forEach((item) => {
          if (
            listofSelectedInvoices.includes(item.docNo) &&
            item.includeInList === true
          ) {
          } else {
            res.creditDropDown.push(item);
          }
        });
      }
    });
  }
  static computeInvoiceandCreditBalances(
    creditList,
    index,
    value,
    creditDropDownSource,
    selectedInvoiceId,
    selectedDatas,
    unchecked?
  ): any {
    if (parseFloat(value) < parseFloat(creditList[index].amount)) {
      creditList[index].creditsApplied = parseFloat(value);
      creditList[index].creditBalance =
        parseFloat(creditList[index].amount) - creditList[index].creditsApplied;
      creditList[index].invoiceBalance = 0;
      if (selectedDatas.pendingBalance !== null) {
        if (parseFloat(selectedDatas.pendingBalance) < parseFloat(creditList[index].amount)) {
          creditList[index].creditBalance =
            parseFloat(creditList[index].amount) - parseFloat(selectedDatas.pendingBalance);
        } else if (parseFloat(selectedDatas.pendingBalance) > parseFloat(creditList[index].amount)) {
          creditList[index].creditBalance = 0;
        }
      };
      creditDropDownSource.forEach((res) => {
        const invoiceNumber = this.checkInvoices(res);
        if (invoiceNumber === selectedInvoiceId && selectedDatas.docNo === res.docNo) {
          this.computePendingBalance(res, creditList, index, value, unchecked);
        }
      });
    } else if (parseFloat(value) > parseFloat(creditList[index].amount)) {
      creditList[index].creditsApplied = parseFloat(creditList[index].amount);
      creditList[index].creditBalance = 0;
      if (selectedDatas.pendingBalance !== null) {
        if (parseFloat(selectedDatas.pendingBalance) < creditList[index].creditsApplied) {
          creditList[index].creditBalance =
            creditList[index].creditsApplied - parseFloat(selectedDatas.pendingBalance);
        }
      };
      creditDropDownSource.forEach((res) => {
        const invoiceNumber = this.checkInvoices(res);
        if (invoiceNumber === selectedInvoiceId && selectedDatas.docNo === res.docNo) {
          this.computePendingBalance(res, creditList, index, value, unchecked);
        }
      });
    } else {
      creditList[index].creditsApplied = parseFloat(creditList[index].amount);
      creditList[index].invoiceBalance = 0;
      creditList[index].creditBalance = 0;
      if (selectedDatas.pendingBalance !== null) {
        if (parseFloat(selectedDatas.pendingBalance) < creditList[index].creditsApplied) {
          creditList[index].creditBalance =
            creditList[index].creditsApplied - parseFloat(selectedDatas.pendingBalance);
        }
      };
      creditDropDownSource.forEach((res) => {
        const invoiceNumber = this.checkInvoices(res);
        if (invoiceNumber === selectedInvoiceId && selectedDatas.docNo === res.docNo) {
          this.computePendingBalance(res, creditList, index, value, unchecked);
        }
      });
    }
  }
  static checkInvoices(data) {
    let selectedData;
    if (data.invoice_docType === 'DZ') {
      selectedData = data.invoice_dochdrtxt;
    } else if (data.invoice_docType === 'YR') {
      selectedData = data.invoice_refhdr;
    } else {
      selectedData = data.refDocNo;
    }
    return selectedData;
  }

  static computePendingBalance(res, creditList, index, value, unchecked?) {
    if (res.pendingBalance === null || res.pendingBalance === 0) {
      res.includeInList = false;
      creditList[index].balanceToBeAdded = creditList[index].creditsApplied;
      res.pendingBalance = res.amount - creditList[index].creditsApplied;
      res.previousBalance = null;
      creditList[index].invoiceBalance =
        parseFloat(value) - creditList[index].creditsApplied;
    } else {
      creditList[index].balanceToBeAdded = creditList[index].creditsApplied;
      if (res.pendingBalance - creditList[index].creditsApplied >= 0) {
        res.pendingBalance =
          res.pendingBalance - creditList[index].creditsApplied;
        res.previousBalance = null;
        creditList[index].invoiceBalance = res.pendingBalance;
      } else {
        creditList[index].creditsApplied = res.pendingBalance;
        creditList[index].balanceToBeAdded = creditList[index].creditsApplied;
        res.previousBalance = res.pendingBalance;
        res.pendingBalance = 0;
        res.includeInList = true;
        creditList[index].invoiceBalance = 0;
      }
    }
    if (res?.pendingBalance <= 0) {
      res.includeInList = true;
      creditList[index].invoiceBalance = 0;
    }
    if (unchecked) {
      res.includeInList = false;
    }
  }
}
